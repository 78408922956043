import { stripRichTextWrapperTag } from '../../utils/html';

export default (sitemap, products, { $enhancedLinkSerializer }) => {
  const sitemapItems = [];
  const marketsIds = [];
  const markets = [];
  const discoverChildren = [];

  products.forEach((product) => {
    if (marketsIds.indexOf(product.data.market.id) === -1) {
      marketsIds.push(product.data.market.id);
      markets.push(product.data.market);
    }
  });

  markets.sort((a, b) => {
    if (a.data.title < b.data.title) return -1;
    if (a.data.title < b.data.title) return 1;
    return 0;
  });

  markets.forEach((market) => {
    market.children = products
      .filter((product) => product.data.market.id === market.id)
      .map((product) => ({
        label: stripRichTextWrapperTag(product.data.title),
        cta: $enhancedLinkSerializer({ link_type: 'Document', ...product }),
      }));
  });

  ['group', 'press', 'finance', 'career'].forEach((block) => {
    if (sitemap[`${block}_label`] && sitemap[`${block}_links`].length) {
      discoverChildren.push({
        title: {
          label: sitemap[`${block}_label`],
          cta: $enhancedLinkSerializer(sitemap[`${block}_link`]),
        },
        items: sitemap[`${block}_links`].map(({ label, link }) => ({
          label: label,
          cta: $enhancedLinkSerializer(link),
        })),
      });
    }
  });

  sitemapItems.push({
    title: sitemap.products_label,
    children: markets.map((market) => {
      return {
        title: {
          label: market.data.title,
          cta: $enhancedLinkSerializer(market),
        },
        items: market.children,
      };
    }),
  });

  if (sitemap.discover_label && discoverChildren.length) {
    sitemapItems.push({
      title: sitemap.discover_label,
      children: discoverChildren,
    });
  }

  ['price', 'quote'].forEach((item) => {
    if (sitemap[`${item}_title`] && sitemap[`${item}_links`].length) {
      sitemapItems.push({
        title: sitemap[`${item}_title`],
        children: [
          {
            items: sitemap[`${item}_links`].map(({ label, link }) => ({
              label: label,
              cta: $enhancedLinkSerializer(link),
            })),
          },
        ],
      });
    }
  });

  return {
    title: stripRichTextWrapperTag(sitemap.sitemap_title),
    items: sitemapItems,
  };
};
